
/* Number */
.number {
    background-color: #1465BB;
  }
  
  .number .container {
    padding: 20px;
  }
  
  .number h3{
    
    font-family: 'Poppins';
    font-size: 35px;
    font-weight: 900;
    color: var(--color-white);
    text-align: center;
  }
  
  .number p{
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 900;
    color: var(--color-white);
    text-align: center;
  }
  