/*Start */
.ParticipeContent{
margin-top: 6%;

}
.circle-donar {
    width: 50px;
    height: 50px;
    background-color: #007bff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }
  
  .custom-bg-color {
    background-color: #b3dbfb;
  }
  
  ol {
    margin-left: -50px; /* Puedes ajustar este valor según tus preferencias */
  }
  
  .titleparticipar {
    font-family: 'Raleway';
    font-weight: 900;
    font-size: 35px;
    color: white;
  }
  
  .parrafoparticipar {
    font-family: 'Poppins';
    font-weight: 400;
    color: white;
  }
  
  .squarework{
    border-radius: 20px; 
  }