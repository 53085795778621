
/* start Chat*/
.circle-content {
    position: fixed;
    cursor: pointer;
    top: 80%;
    margin-left: 90%;
    z-index: 10;
    background-color: var(--color-white);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    transition: background-color 0.3s ease;
  }
  
  .circle-content img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .circle-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .circle-content:hover {
    background-color: var(--color-primary);
  }
  
  .circle-content:hover::before {
    opacity: 1;
  }
  
  
  @media (max-width: 591px) {
    .circle-content {
      position: fixed;
      top: 85%;
      margin-left: 75%;
      z-index: 1;
      background-color: var(--color-white);
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  
  @media (min-width: 592px) and (max-width: 991px) {
    .circle-content {
      position: fixed;
      top: 85%;
      margin-left: 85%;
      z-index: 1;
      background-color: var(--color-white);
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  