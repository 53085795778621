

  
  .blue-box {
    background-color: var(--color-cornflowerblue-200);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .blue-box h3 {
  color: var(--color-white);
  font-family: 'Raleway';
  font-weight: 900;
  font-size: 25px;
  }
  
  .blue-box p {
    color: var(--color-white);
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 15px;
  }
  
  
  .blue-box .circle {
    background-color: var(--color-white);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .title_caracteristicas {
    margin-top: 9%;
    font-family: 'Raleway';
    font-weight: 900;
    font-size: 30px;
    text-align: center;
    margin-bottom: 2%;
  }
  
  .container_caracteristicas{
  margin-top: 6%;
  padding: 50px;
  }
  
  /*Information*/
  .information{
    margin-top: 13%;
  }
  
  .image-container {
    position: relative;
    background-color: #fff;
  }
  @media (min-width: 992px) {
  
  .image-container img {
  
    width: 70%;
    height: 600px;
    object-fit: cover;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
  }
  
  }
  
  .content-box {
    
    background-color: #fff;
    height: 400px;
    width: 400px;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 55%;
    margin-top: 5%;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }
  
  .container_information {
    padding: 40px;
    text-align: left;
    color: black;
  }
  
  .container_information h4 {
    font-family: 'Raleway';
    color: var(--color-cornflowerblue-100);
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .container_information h2 {
    font-family: 'Raleway';
    font-weight: 900;
    font-size: 25px;
  }
  
  .container_information p {
    font-size: 14px;
    line-height: 1.5;
  }
  
  
  .icono-texto {
    display: flex;
    align-items: center;
  }
  
  .icono-texto i {
    margin-right: 10px;
  }
  
  .icono-texto span {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  
  .blue-box {
    background-color: var( --color-cornflowerblue-100);
    height: 70%;
  }
  
  .content_blue_box{
    font-weight: 900;
    color: #fff;
  }
  
  .content_blue_box h1{
  text-align: center;
  font-family: 'Raleway';
  font-size: 40px;
  }
  
  .content_blue_box p{
    text-align: center;
    font-family: 'Raleway';
    font-size: 10.8px;
  }
  
  /* Tablet */
  @media (min-width: 591px) and (max-width: 991px) {
  
    .image-container {
      position: relative;
      background-color: #fff;
    }
    
    .image-container img {
    
      width: 60%;
      height: 500px;
      object-fit: cover;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    }
    
    .content-box {
      
      background-color: #fff;
      height: 400px;
      width: 400px;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 30%;
      margin-top: 5%;
      border-radius: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    }
    
    .container_information {
      padding: 40px;
      text-align: left;
      color: black;
    }
    
    .container_information h4 {
      font-family: 'Raleway';
      color: var(--color-cornflowerblue-100);
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 10px;
    }
    
    .container_information h2 {
      font-family: 'Raleway';
      font-weight: 900;
      font-size: 25px;
    }
    
    .container_information p {
      font-size: 14px;
      line-height: 1.5;
    }
    
    
    .icono-texto {
      display: flex;
      align-items: center;
    }
    
    .icono-texto i {
      margin-right: 10px;
    }
    
    .icono-texto span {
      font-weight: bold;
    }
    
    
    .blue-box {
      background-color: blue;
      height: 70%;
    }
    
    .content_blue_box{
      font-weight: 900;
      color: #fff;
    }
    
    .content_blue_box h1{
    text-align: center;
    font-family: 'Raleway';
    font-size: 40px;
    }
    
    .content_blue_box p{
      text-align: center;
      font-family: 'Raleway';
      font-size: 10.8px;
    }
   
  
  }
  
  
  
  /* Movil*/
  @media (max-width: 591px) {
  
    .information {
      position: relative;
    }
    
    .image-container {
      position: relative;
      overflow: hidden;
    }
    
    .image-container img {
      width: 100%;
      height: 50%;
      display: block;
      object-fit: cover;
    }
    
    .content-box {
      position: absolute;
      top: 45%;
      left: -3%;
      transform: translate(-50%, -50%);
      width: 85%;
      height: 72%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    }
  
    .container_information p {
      margin-top: 20px;
      font-size: 14px;
      line-height: 1.5;
    }
    
  }
  
  
  /* end carcteristicas*/