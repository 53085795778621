.donantes{
    margin-top: 15%;
    margin-bottom: 20%;
  }
  .donantes .containerImg{
    margin-bottom: 5%;
  }
  .donantes .container h2{
    font-family: 'Raleway';
    font-weight: 900;
  }
  
  .donantes .container p{
    font-family: 'Poppins';
    font-weight: 400;
    margin-bottom: 6%;
  }
  
  .donantes-img-container {
  margin-top: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .donantes-img {
    max-width: 100%;
    max-height: 100px;
  }
  
  #onu {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  